const bloodPreGrade = [
  {
    value: "分级",
    High: "收缩压(mmHg)",
    Low: "舒张压(mmHg)",
  },
  {
    value: "正常血压",
    High: "<120和",
    Low: "<80",
  },
  {
    value: "正常高值",
    High: "120～139和（或）",
    Low: "80～89",
  },
  {
    value: "高血压",
    High: "≥140和（或）",
    Low: "≥90",
  },
  {
    value: "1级高血压(中度)",
    High: "140～159和（或）",
    Low: "90～99",
  },
  {
    value: "2级高血压(中度)",
    High: "160～179和（或）",
    Low: "100～109",
  },
  {
    value: "3级高血压(重度)",
    High: "≥180和（或）",
    Low: "≥110",
  },
  {
    value: "单纯收缩期高血压",
    High: "≥140和（或）",
    Low: "<90",
  },
];

export { bloodPreGrade };
