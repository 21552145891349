const hHDictionary = {
  Height: {
    unit: "cm",
    value: "身高",
  },
  Weight: {
    range: "53.5-72.0",
    unit: "kg",
    value: "体重",
  },
  Bmi: {
    range: "18.5-24.9",
    unit: "",
    value: "体重指数(BMI)",
  },
  BasalMetabolism: {
    unit: "kcal",
    value: "基础代谢率",
  },
  BodyWater: {
    unit: "kg",
    value: "身体水分",
  },
  BoneWeight: {
    range: "23.2",
    unit: "kg",
    value: "推定骨量",
  },
  FatWeight: {
    range: "7.1-16.0",
    unit: "kg",
    value: "脂肪含量",
  },
  FatPercentage: {
    range: "11.0-21.9",
    unit: "%",
    value: "体脂肪率",
  },
  PhysicalAge: {
    unit: "岁",
    value: "身体年龄",
  },
  NoFatWeight: {
    unit: "kg",
    value: "除脂肪量",
  },
  BodyFatRating: {
    unit: "",
    value: "内脏脂肪等级",
  },
  MuscleMass: {
    range: "24.2-28.8",
    unit: "kg",
    value: "肌肉量",
  },
  LeftHandMuscleScore: {
    range: "-4-4",
    unit: "",
    value: "肌肉量 左手得分",
  },
  RightHandMuscleScore: {
    range: "-4-4",
    unit: "",
    value: "肌肉量 右手得分",
  },
  LeftFootMuscleScore: {
    range: "-4-4",
    unit: "",
    value: "肌肉量 左脚得分",
  },
  RightFootMuscleScore: {
    range: "-4-4",
    unit: "",
    value: "肌肉量 右脚得分",
  },
  BodyMuscleScore: {
    range: "-4-4",
    unit: "",
    value: "肌肉量 身体得分",
  },
  LeftHandFatScore: {
    range: "-4-4",
    unit: "",
    value: "体脂肪率 左手得分",
  },
  RightHandFatScore: {
    range: "-4-4",
    unit: "",
    value: "体脂肪率 右手得分",
  },
  LeftFootFatScore: {
    range: "-4-4",
    unit: "",
    value: "体脂肪率 左脚得分",
  },
  RightFootFatScore: {
    range: "-4-4",
    unit: "",
    value: "体脂肪率 右脚得分",
  },
  BodyFatScore: {
    range: "-4-4",
    unit: "",
    value: "体脂肪率 身体得分",
  },
  Sex: {
    formatter: (data) => {
      if (data == 1) {
        return "男";
      } else {
        return "女";
      }
    },
    unit: "",
    value: "性别",
  },
  Age: {
    unit: "岁",
    value: "年龄",
  },
  ExtracellularFluid: {
    unit: "kg",
    value: "细胞外液量",
  },
  IntracellularFluid: {
    unit: "kg",
    value: "细胞内液量",
  },
  BasalMetabolismKj: {
    unit: "kj",
    value: "基础代谢量",
  },
  BodyWaterPercentage: {
    range: "55.0-65.0",
    unit: "%",
    value: "体水分率",
  },
  ExtracellularFluidPercentage: {
    unit: "%",
    value: "细胞外液率",
  },
  LeftHandMuscle: {
    unit: "kg",
    value: "肌肉量 左手",
  },
  RightHandMuscle: {
    unit: "kg",
    value: "肌肉量 右手",
  },
  LeftFootMuscle: {
    unit: "kg",
    value: "肌肉量 左脚",
  },
  gugeji: {
    unit: "%",
    value: "骨骼肌",
  },
  danbai: {
    unit: "kg",
    value: "蛋白质",
  },
  jiroulv: {
    unit: "%",
    value: "肌肉率",
  },
  RightFootMuscle: {
    unit: "kg",
    value: "肌肉量 右脚",
  },
  BodyMuscleMass: {
    unit: "kg",
    value: "肌肉量 身体",
  },
  LeftHandFatPercentage: {
    unit: "%",
    value: "体脂肪率 左手",
  },
  LeftHandFat: {
    unit: "kg",
    value: "体脂肪 左手",
  },
  RightHandFatPercentage: {
    unit: "%",
    value: "体脂肪率 右手",
  },
  RightHandFat: {
    unit: "kg",
    value: "体脂肪 右手",
  },
  LeftFootFatPercentage: {
    unit: "%",
    value: "体脂肪率 左脚",
  },
  LeftFootFat: {
    unit: "kg",
    value: "体脂肪 左脚",
  },
  RightFootFatPercentage: {
    unit: "%",
    value: "体脂肪率 右脚",
  },
  RightFootFat: {
    unit: "kg",
    value: "体脂肪 右脚",
  },
  BodyFatPercentage: {
    unit: "%",
    value: "体脂肪率 身体",
  },
  AdultPercent: {
    unit: "%",
    value: "成人比",
  },
  SameAgePercent: {
    unit: "%",
    value: "同龄比",
  },
  BodyFat: {
    unit: "kg",
    value: "体脂肪 身体",
  },
  StandardWeight: {
    unit: "kg",
    value: "标准体重",
  },
  Bua: {
    unit: "",
    value: "Bua",
  },
  Oi: {
    range: ">52.55",
    unit: "",
    value: "Oi",
  },
  Sos: {
    range: "--",
    unit: "",
    value: "SOS(骨密度)",
  },
  Tscore: {
    range: ">-1",
    unit: "",
    value: "T值(骨密度)",
  },
  Zscore: {
    range: ">-2",
    unit: "",
    value: "Z值(骨密度)",
  },
  Foot: {
    formatter: (data) => {
      if (data == "left") {
        return "左脚";
      } else {
        return "右脚";
      }
    },
    unit: "",
    value: "骨密度测量部位",
  },
  Avi: {
    range: "≤16.0",
    unit: "",
    value: "中心动脉硬度指标",
  },
  Api: {
    range: "≤22.0",
    unit: "",
    value: "上臂动脉硬度指标",
  },
  Csbp: {
    range: "≤130.0",
    unit: "",
    value: "中心动脉血压差",
  },
  Capp: {
    range: "20.0～60.0",
    unit: "",
    value: "中心动脉脉压差",
  },
  Heart: {
    range: "60.0~100.0",
    unit: "bpm",
    value: "心率（血压）",
  },
  Low: {
    range: "60.0~80.0",
    unit: "mmHg",
    value: "舒张压",
  },
  High: {
    range: "90.0~120.0",
    unit: "mmHg",
    value: "收缩压",
  },
};
export { hHDictionary };
