import { mapHelper } from "@/utils/utils.js";

//bmi肥胖
const bmiArr = [
  {
    text: "偏低",
    value: "1",
  },
  {
    text: "正常",
    value: "2",
  },
  {
    text: "超重",
    value: "3",
  },
  {
    text: "肥胖",
    value: "4",
  },
];

const { map: bmiArrMap, setOps: bmiArrOps } = mapHelper.setMap(bmiArr);
//bmi肥胖
const bmiAdArr = [
  {
    text: "蓝绿健康提醒您，体重指数小于18.5时可能存在营养不良的情况，建议摄入营养丰富的食物及多补充蛋白质，如牛肉、鸡肉、牛奶、鸡蛋。",
    value: "1",
  },
  {
    text: "蓝绿健康提醒您，目前您的体重指数在健康范围内，请继续保持饮食均衡，多吃新鲜蔬菜和水果，积极参加体育锻炼，避免暴饮暴食，保持良好的生活饮食习惯即可远离肥胖。",
    value: "2",
  },
  {
    text: "蓝绿健康提醒您，成年后的体重增长最好控制在5kg以内，超过10kg会增加相关疾病的危险；一般成人每天摄入能量控制在1200-1300kcal，少吃肥肉等富含脂肪及胆固醇的食物，坚持规律的饮食习惯将会距离健康越来越近",
    value: "3",
  },
  {
    text: "蓝绿健康提醒您，肥胖可能会引发诸多的肥胖相关并发症，需要及时干预采取措施，鉴于您目前的情况，建议适当增加身体活动量，少吃油腻食物及零食，推荐每周至少5天、每天大约60分钟中等强度有氧运动，比如游泳、骑自行车等，每月减重1-2kg为宜。",
    value: "4",
  },
];
const { map: bmiAdArrMap, setOps: bmiAdArrOps } = mapHelper.setMap(bmiAdArr);

//体脂率
const tizhilvArr = [
  {
    text: "偏低",
    value: "1",
  },
  {
    text: "正常",
    value: "2",
  },
  {
    text: "偏高",
    value: "3",
  },
  {
    text: "过高",
    value: "4",
  },
];
const { map: tizhilvArrMap, setOps: tizhilvArrOps } =
  mapHelper.setMap(tizhilvArr);
//身体得分
const bodyScoreArr = [
  {
    text: "优秀",
    value: "1",
  },
  {
    text: "良好",
    value: "2",
  },
  {
    text: "较差",
    value: "3",
  },
];
const { map: bodyScoreArrMap, setOps: bodyScoreArrOps } =
  mapHelper.setMap(bodyScoreArr);
//身体年龄
const bodyAgeArr = [
  {
    text: "年轻",
    value: "1",
  },
  {
    text: "偏大",
    value: "2",
  },
];
const { map: bodyAgeArrMap, setOps: bodyAgeArrOps } =
  mapHelper.setMap(bodyAgeArr);
//内脏等级
const neizangArr = [
  {
    text: "正常",
    value: "2",
  },
  {
    text: "超标",
    value: "3",
  },
  {
    text: "过多",
    value: "4",
  },
];
const { map: neizangArrMap, setOps: neizangArrOps } =
  mapHelper.setMap(neizangArr);
export {
  bmiArrMap,
  bmiAdArrMap,
  tizhilvArrMap,
  bodyScoreArrMap,
  bodyAgeArrMap,
  neizangArrMap,
};
