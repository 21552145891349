import * as echarts from "echarts";

/// 饼图
export function drawBarChart(eleId, xData, seriesData, title, data) {
  let option = {
    grid: {
      left: "0px",
      right: "0px",
      bottom: "0px",
      top: "0px",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["60%", "100%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            position: "center",
            color: "#4c4a4a",
            formatter:
              "{total|" + data + "}" + "\n\r" + "{active|" + title + "}",
            rich: {
              total: {
                fontSize: 18,
                color: "#089BAB",
                lineHeight: 30,
              },
              active: {
                fontSize: 14,
                color: "#089BAB",
                lineHeight: 14,
              },
            },
          },
          emphasis: {
            //中间文字显示
            show: true,
          },
        },
        labelLine: {
          show: false,
        },
        data: seriesData ? seriesData : [],
      },
    ],
  };
  drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}

// 绘制图表
function drawedChart(eleId, option) {
  let chart = null;
  if (document.getElementById(eleId)) {
    chart = echarts.init(document.getElementById(eleId));
    chart.setOption(option);
  }
  return chart;
}
