//AVI数据
const aviArr = [
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "15.0,18.0,22.5",
  "16.0,19.2,24.0",
  "16.0,19.2,24.0",
  "16.0,19.2,24.0",
  "17.0,20.4,25.5",
  "17.0,20.4,25.5",
  "18.0,21.6,27.0",
  "18.0,21.6,27.0",
  "18.0,21.6,27.0",
  "18.0,21.6,27.0",
  "18.0,21.6,27.0",
  "18.0,21.6,27.0",
  "18.0,21.6,27.0",
  "19.0,22.8,28.5",
  "19.0,22.8,28.5",
  "20.0,24.0,30.0",
  "20.0,24.0,30.0",
  "20.0,24.0,30.0",
  "21.0,25.2,31.5",
  "21.0,25.2,31.5",
  "21.0,25.2,31.5",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "23.0,27.6,34.5",
  "23.0,27.6,34.5",
  "23.0,27.6,34.5",
  "24.0,28.8,36.0",
  "24.0,28.8,36.0",
  "24.0,28.8,36.0",
  "25.0,30.0,37.5",
  "25.0,30.0,37.5",
  "26.0,31.2,39.0",
  "26.0,31.2,39.0",
  "26.0,31.2,39.0",
  "27.0,32.4,40.5",
  "27.0,32.4,40.5",
  "28.0,33.6,42.0",
  "28.0,33.6,42.0",
  "29.0,34.8,43.5",
  "29.0,34.8,43.5",
  "30.0,36.0,45.0",
  "30.0,36.0,45.0",
  "31.0,37.2,46.5",
  "31.0,37.2,46.5",
  "32.0,38.4,48.0",
  "32.0,38.4,48.0",
  "33.0,39.6,49.5",
  "33.0,39.6,49.5",
  "33.0,39.6,49.5",
  "34.0,40.8,51.0",
  "35.0,42.0,52.5",
  "35.0,42.0,52.5",
  "36.0,43.2,54.0",
  "37.0,44.4,55.5",
  "37.0,44.4,55.5",
  "38.0,45.6,57.0",
  "39.0,46.8,58.5",
];
//avi建议
const aviAdvice = {
  1: "动脉硬化程度评估结果为正常，建议日常保持良好的生活饮食习惯，多运动多吃新鲜蔬菜水果，戒烟、低盐低脂饮食。",
  2: "动脉硬化程度评估结果为轻度偏高，蓝绿健康建议您做心血管方面的检查（如超声波、心电图等）来进一步了解您的健康状况，同时坚持体育锻炼，如慢跑、快走等，可以有效预防动脉粥样硬化。",
  3: "动脉硬化程度评估结果为中度偏高，蓝绿健康建议您进行心血管系统全面检查，并遵医嘱进行合理的治疗或药物的服用。",
  4: "动脉硬化程度评估结果为重度偏高，一般提示有血管系统可查见的器官或组织病变，蓝绿健康提醒您需及时就医。",
};
//api数据
const apiArr = [
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "22.0,26.4,33.0",
  "23.0,27.6,34.5",
  "23.0,27.6,34.5",
  "23.0,27.6,34.5",
  "23.0,27.6,34.5",
  "23.0,27.6,34.5",
  "23.0,27.6,34.5",
  "24.0,28.8,36.0",
  "24.0,28.8,36.0",
  "24.0,28.8,36.0",
  "24.0,28.8,36.0",
  "24.0,28.8,36.0",
  "25.0,30.0,37.5",
  "25.0,30.0,37.5",
  "25.0,30.0,37.5",
  "25.0,30.0,37.5",
  "25.0,30.0,37.5",
  "25.0,30.0,37.5",
  "26.0,31.2,39.0",
  "26.0,31.2,39.0",
  "26.0,31.2,39.0",
  "26.0,31.2,39.0",
  "27.0,32.4,40.5",
  "27.0,32.4,40.5",
  "27.0,32.4,40.5",
  "27.0,32.4,40.5",
  "27.0,32.4,40.5",
  "27.0,32.4,40.5",
  "27.0,32.4,40.5",
  "28.0,33.6,42.0",
  "28.0,33.6,42.0",
  "28.0,33.6,42.0",
  "28.0,33.6,42.0",
  "29.0,34.8,43.5",
  "29.0,34.8,43.5",
  "29.0,34.8,43.5",
  "29.0,34.8,43.5",
  "30.0,36.0,45.0",
  "30.0,36.0,45.0",
  "30.0,36.0,45.0",
  "30.0,36.0,45.0",
  "31.0,37.2,46.5",
  "31.0,37.2,46.5",
  "31.0,37.2,46.5",
  "31.0,37.2,46.5",
  "31.0,37.2,46.5",
  "32.0,38.4,48.0",
  "32.0,38.4,48.0",
  "32.0,38.4,48.0",
  "32.0,38.4,48.0",
  "32.0,38.4,48.0",
  "32.0,38.4,48.0",
  "33.0,39.6,49.5",
  "33.0,39.6,49.5",
  "33.0,39.6,49.5",
];
const apiAdvice = {
  1: "动脉硬化程度评估结果为正常，建议日常保持良好的生活饮食习惯，多运动多吃新鲜蔬菜水果，戒烟、低盐低脂饮食。",
  2: "动脉硬化程度评估结果为轻度偏高，蓝绿健康建议您做心血管方面的检查（如超声波、心电图等）来进一步了解您的健康状况，同时坚持体育锻炼，如慢跑、快走等，可以有效预防动脉粥样硬化。",
  3: "动脉硬化程度评估结果为中度偏高，蓝绿健康建议您进行心血管系统全面检查，并遵医嘱进行合理的治疗或药物的服用。",
  4: "动脉硬化程度评估结果为重度偏高，一般提示有血管系统可查见的器官或组织病变，蓝绿健康提醒您需及时就医。",
};
//血压建议
const bloodPreAdvice = {
  1: "蓝绿健康提醒您，血压处于偏低范围，建议您日常保持营养均衡，可以多摄入富含蛋白质、维生素的食物，如猪肝、豆奶、鱼虾等；蹲起站立时避免动作过猛导致头晕摔倒。",
  2: "蓝绿健康提醒您，规律饮食及坚持运动是保持健康的良好方式，多吃水果蔬菜，保持良好的心情可远离疾病。",
  3: "蓝绿健康提醒您，您的血压处于高血压临界值，需要及时干预避免高血压的发生，日常限制钠的摄入，每日食盐量不超过6g，避免吃腌制品等，保持稳定的情绪和良好的体重将会远离高血压。",
  4: "蓝绿健康提醒您，高血压会引发诸多心血管并发症，鉴于您目前的情况，建议您纠正过咸的口味，少吃零食，限制饮酒和戒烟，适当增加身体活动，长时间静坐是高血压发生发展的重要因素。",
  5: "蓝绿健康提醒您，高血压会引发诸多心血管并发症，鉴于您目前的情况，建议您可做有氧运动，每周宜5-7天，每次10分钟逐渐增加至30分钟，以中低强度为主。",
  6: "蓝绿健康提醒您，高血压会引发诸多心血管并发症，鉴于您目前的情况，建议您谨遵医嘱服用药物，日常避免情绪激动，保持良好的心情；不要过度剧烈运动，运动时间逐渐延长，安静时血压不能很好控制或者超过180/110mmHg时需要暂时禁止中度及以上运动。",
};
//骨密度建议
const boneMDAdvice = {
  1: "蓝绿健康提醒您，多补充蛋白质及维生素D可预防骨质疏松，让您避免骨质疏松带来的危害。",
  2: "蓝绿健康提醒您，建议您可以多摄入钙质丰富的食物，多晒太阳促进钙吸收，可遵医嘱额外摄入维生素D，保持良好的运动习惯，多做负重运动和抗阻运动，注意运动安全，避免跌倒。",
  3: "蓝绿健康提醒您，骨质疏松时需要合理的营养，均衡饮食，多吃含钙、磷高的食物，比如奶制品、豆类、海带等，注意补充维生素D，可摄入油、肝、奶等；戒烟限酒，少喝浓咖啡、浓茶等；保持适度的体重，运动时避免剧烈运动及跌倒；每天可增加日照，晒太阳20-30分钟。",
};
export { aviArr, aviAdvice, apiArr, apiAdvice, bloodPreAdvice, boneMDAdvice };
