<template>
  <div class="healthReport">
    <div class="healthReport-t">
      <div
        v-for="(item, index) in titleList"
        :key="index"
        @click="selectTitle(item)"
        :class="['item', { active: item.key == oKey }]"
      >
        {{ item.value }}
      </div>
    </div>
    <div class="healthReport-c" v-if="oKey == heightWeight.examItemCode">
      <div class="title">身高体重检测</div>
      <div class="intro">
        身体质量指数（BMI）又称为体重指数、体质指数，该指标在一定程度可以反映人体密度，
        用于评价人体的营养状况、胖瘦程度或身体发育水平。
      </div>
      <div class="heightWight thirdItem">
        <div
          v-for="(item, index) in heightWeight.examResultList"
          :key="index"
          :class="['item', { active: item.itemName == 'Bmi' }]"
        >
          <div class="item-t">{{ hHDictionary[item.itemName].value }}</div>
          <div :class="['item-b']">
            {{ item.itemValue }}
          </div>
        </div>
      </div>
      <div class="title">BMI结果分布</div>
      <div class="BMI">
        <div class="targetItem" v-if="heightWeightData.Bmi">
          <div class="item1 item">
            <div class="item-t">
              <div
                class="circle"
                v-if="
                  returnCircle(
                    heightWeightData.Bmi.itemValue,
                    0,
                    18.5,
                    36,
                    0,
                    '最小'
                  )
                "
                :style="{
                  left: returnLeft(
                    heightWeightData.Bmi.itemValue,
                    0,
                    18.5,
                    36,
                    0,
                    164
                  ),
                }"
              >
                <div class="numItem">
                  {{ heightWeightData.Bmi.itemValue }}
                </div>
              </div>
            </div>
            <div class="item-b">偏低</div>
          </div>

          <div class="item3 item">
            <div class="num">18.5</div>
            <div class="item-t">
              <div
                class="circle"
                :style="{
                  left: returnLeft(
                    heightWeightData.Bmi.itemValue,
                    18.5,
                    24.0,
                    36,
                    0,
                    164
                  ),
                }"
                v-if="
                  returnCircle(
                    heightWeightData.Bmi.itemValue,
                    18.5,
                    24.0,
                    36,
                    0
                  )
                "
              >
                <div class="numItem">
                  {{ heightWeightData.Bmi.itemValue }}
                </div>
              </div>
            </div>
            <div class="item-b">正常</div>
          </div>
          <div class="item4 item">
            <div class="num">24.0</div>
            <div class="item-t">
              <div
                class="circle"
                :style="{
                  left: returnLeft(
                    heightWeightData.Bmi.itemValue,
                    24.0,
                    28.0,
                    36,
                    0,
                    164
                  ),
                }"
                v-if="
                  returnCircle(
                    heightWeightData.Bmi.itemValue,
                    24.0,
                    28.0,
                    36,
                    0
                  )
                "
              >
                <div class="numItem">
                  {{ heightWeightData.Bmi.itemValue }}
                </div>
              </div>
            </div>
            <div class="item-b">超重</div>
          </div>
          <div class="item6 item">
            <div class="num">28.0</div>
            <div class="item-t">
              <div
                class="circle"
                :style="{
                  left: returnLeft(
                    heightWeightData.Bmi.itemValue,
                    28.0,
                    36,
                    36,
                    0,
                    164
                  ),
                }"
                v-if="
                  returnCircle(
                    heightWeightData.Bmi.itemValue,
                    28.0,
                    36,
                    36,
                    0,
                    '最大'
                  )
                "
              >
                <div class="numItem">
                  {{ heightWeightData.Bmi.itemValue }}
                </div>
              </div>
            </div>
            <div class="item-b">肥胖</div>
          </div>
        </div>
      </div>
      <div class="advise">{{ heightWeightData.advise }}</div>
    </div>
    <div
      class="healthReport-c peopleComponent"
      v-if="oKey == peopleComponent.examItemCode"
    >
      <div class="peopleComponent-t">
        <div class="peopleComponent-tt">
          <div class="peopleComponent-ttl" v-if="peopleComponentData.Bmi">
            <img
              class="fat1"
              src="./img/fat1.png"
              v-if="checkBmiLevel(peopleComponentData.Bmi.itemValue) == 1"
              alt=""
            />
            <img
              class="fat2"
              src="./img/fat2.png"
              v-if="checkBmiLevel(peopleComponentData.Bmi.itemValue) == 2"
              alt=""
            />
            <img
              class="fat3"
              src="./img/fat3.png"
              v-if="checkBmiLevel(peopleComponentData.Bmi.itemValue) == 3"
              alt=""
            />
            <img
              class="fat4"
              src="./img/fat4.png"
              v-if="checkBmiLevel(peopleComponentData.Bmi.itemValue) == 4"
              alt=""
            />
          </div>
          <div class="peopleComponent-ttr">
            <div class="peopleComponent-ttrl" v-if="peopleComponentData.Weight">
              <div class="peopleComponent-ttrlt">
                {{ peopleComponentData.Weight.createTime }}
              </div>
              <div class="peopleComponent-ttrlb">
                {{ peopleComponentData.Weight.itemValue }}
                <span>{{
                  peopleComponentData.Weight.itemName &&
                  hHDictionary[peopleComponentData.Weight.itemName]
                    ? hHDictionary[peopleComponentData.Weight.itemName].unit
                    : ""
                }}</span>
              </div>
            </div>
            <div class="peopleComponent-ttrr" v-if="peopleComponentData.Bmi">
              <div class="peopleComponent-ttrrt">
                {{
                  peopleComponentData.Bmi.itemName &&
                  hHDictionary[peopleComponentData.Bmi.itemName]
                    ? hHDictionary[peopleComponentData.Bmi.itemName].value
                    : ""
                }}
              </div>
              <div class="peopleComponent-ttrrb">
                {{
                  peopleComponentData.Bmi
                    ? peopleComponentData.Bmi.itemValue
                    : ""
                }}
              </div>

              <div
                v-if="peopleComponentData.Bmi"
                :class="[
                  'bmiData',
                  'oActive',
                  'oActive' + checkBmiLevel(peopleComponentData.Bmi.itemValue),
                ]"
              >
                {{
                  bmiArrMap[checkBmiLevel(peopleComponentData.Bmi.itemValue)]
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="peopleComponent-tb">
          <div
            v-for="(item, index) in peopleComponentList"
            :key="index"
            v-show="returnIsCom(item.itemName)"
            :class="['item', `${item.itemName}`]"
          >
            <div class="item-t">
              <p>
                {{ item.itemValue
                }}{{
                  hHDictionary[item.itemName] &&
                  hHDictionary[item.itemName].unit
                    ? hHDictionary[item.itemName].unit
                    : ""
                }}
              </p>
              <p class="returnIsComTxt">{{ item.extra }}</p>
            </div>
            <div class="item-b">
              <div
                class="img"
                v-if="returnIsCom(item.itemName) && item.itemName"
              >
                <img
                  :src="
                    returnIsCom(item.itemName)
                      ? require(`./img/${item.itemName}.png`)
                      : ''
                  "
                  alt=""
                />
              </div>
              <div class="txt">
                {{
                  hHDictionary[item.itemName] &&
                  hHDictionary[item.itemName].value
                    ? hHDictionary[item.itemName].value
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="peopleComponent-c">{{ peopleComponentData.advise }}</div>
      <div class="peopleComponent-b">
        <div class="title">体重组成</div>
        <div class="peopleComponent-box">
          <div class="Weight item">
            <div class="item-t">
              <div id="Weight"></div>
            </div>
            <div class="item-b">
              <div class="item-bt" v-if="peopleComponentData.FatWeight">
                脂肪{{ peopleComponentData.FatWeight["itemValue"] }}
                {{
                  peopleComponentData.FatWeight.itemName &&
                  hHDictionary[peopleComponentData.FatWeight.itemName].value
                    ? hHDictionary[peopleComponentData.FatWeight.itemName].unit
                    : ""
                }}
              </div>
              <div class="item-bb" v-if="peopleComponentData.NoFatWeight">
                去脂体重{{ peopleComponentData.NoFatWeight.itemValue }}kg
              </div>
            </div>
          </div>
          <div class="NoFat item">
            <div class="item-t">
              <div id="NoFat"></div>
            </div>
            <div class="item-b">
              <div class="item-bt" v-if="peopleComponentData.BoneWeight">
                骨量{{ peopleComponentData.BoneWeight["itemValue"] }}
                {{
                  peopleComponentData.BoneWeight.itemName &&
                  hHDictionary[peopleComponentData.BoneWeight.itemName].value
                    ? hHDictionary[peopleComponentData.BoneWeight.itemName].unit
                    : ""
                }}
              </div>
              <div class="item-bb" v-if="peopleComponentData.MuscleMass">
                肌肉{{ peopleComponentData.MuscleMass.itemValue }}kg
              </div>
            </div>
          </div>
          <div class="MuscleMass item">
            <div class="item-t">
              <div id="MuscleMass"></div>
            </div>
            <div class="item-b">
              <div class="item-bt" v-if="peopleComponentData.danbai">
                蛋白质{{ peopleComponentData.danbai["itemValue"] }}kg
              </div>
              <div class="item-bb" v-if="peopleComponentData.BodyWater">
                水分{{ peopleComponentData.BodyWater.itemValue }}kg
              </div>
            </div>
          </div>
        </div>
        <div class="title">详细数据</div>
        <div class="peopleComponentList">
          <div
            class="item"
            v-for="(item, index) in peopleComponentList"
            :key="index"
            v-show="item.itemName"
          >
            <div class="item-t" v-if="item.itemName">
              {{
                hHDictionary[item.itemName] && hHDictionary[item.itemName].value
                  ? hHDictionary[item.itemName].value
                  : ""
              }}
            </div>
            <div :class="['item-c', 'oActive', item.class]">
              {{ item.extra }}
            </div>
            <div class="item-b" v-if="item.itemName">
              {{
                hHDictionary[item.itemName] &&
                hHDictionary[item.itemName].formatter
                  ? hHDictionary[item.itemName].formatter(item.itemValue)
                  : item.itemValue
              }}{{
                item.itemName && hHDictionary[item.itemName]
                  ? hHDictionary[item.itemName].unit
                  : ""
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="healthReport-c" v-if="oKey == bloodPre.examItemCode">
      <div class="dongmai">
        <div class="title">动脉硬化评估</div>
        <div class="intro">
          动脉硬化是动脉的一种非炎症性病变，可使动脉管壁增厚、变硬，失去弹性、管腔狭窄。
          定期检查对于脑中风、脑溢血、心肌梗塞、高血压等心脑血管疾病的防治具有重要意义。
        </div>
        <div class="title">中心动脉硬度指标（Avi）</div>
        <div class="intro">
          AVI是指中心动脉硬化程度指标，是将最高血压以上的高袖套压脉波波形的特征加以数据化，数值越高表明动脉硬化的风险越高。
        </div>
        <div class="Avi">
          <div class="targetItem" v-if="bloodPreData.Avi">
            <div class="item1 item">
              <div class="item-t">
                <div
                  class="circle"
                  v-if="
                    returnCircle(
                      bloodPreData.Avi.itemValue,
                      0,
                      AviLevel[0],
                      Number(AviLevel[2]) + 4,
                      0,
                      '最小'
                    )
                  "
                  :style="{
                    left: returnLeft(
                      bloodPreData.Avi.itemValue,
                      0,
                      AviLevel[0],
                      Number(AviLevel[2]) + 4,
                      0,
                      164
                    ),
                  }"
                >
                  <div class="numItem">{{ bloodPreData.Avi.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">正常</div>
            </div>
            <div class="item2 item">
              <div class="num">{{ AviLevel[0] }}</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.Avi.itemValue,
                      AviLevel[0],
                      AviLevel[1],
                      Number(AviLevel[2]) + 4,
                      0,
                      164
                    ),
                  }"
                  v-if="
                    returnCircle(
                      bloodPreData.Avi.itemValue,
                      AviLevel[0],
                      AviLevel[1],
                      Number(AviLevel[2]) + 4,
                      0
                    )
                  "
                >
                  <div class="numItem">{{ bloodPreData.Avi.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">轻度</div>
            </div>
            <div class="item4 item">
              <div class="num">{{ AviLevel[1] }}</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.Avi.itemValue,
                      AviLevel[1],
                      AviLevel[2],
                      Number(AviLevel[2]) + 4,
                      0,
                      164
                    ),
                  }"
                  v-if="
                    returnCircle(
                      bloodPreData.Avi.itemValue,
                      AviLevel[1],
                      AviLevel[2],
                      Number(AviLevel[2]) + 4,
                      0
                    )
                  "
                >
                  <div class="numItem">{{ bloodPreData.Avi.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">中度</div>
            </div>
            <div class="item5 item">
              <div class="num">{{ AviLevel[2] }}</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.Avi.itemValue,
                      AviLevel[2],
                      Number(AviLevel[2]) + 4,
                      Number(AviLevel[2]) + 4,
                      0,
                      164
                    ),
                  }"
                  v-if="
                    returnCircle(
                      bloodPreData.Avi.itemValue,
                      AviLevel[2],
                      Number(AviLevel[2]) + 4,
                      Number(AviLevel[2]) + 4,
                      0,
                      '最大'
                    )
                  "
                >
                  <div class="numItem">{{ bloodPreData.Avi.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">重度</div>
            </div>
          </div>
        </div>
        <div class="title">上臂动脉硬度指标（API）</div>
        <div class="intro">
          API是指上臂动脉硬化程度指标，随着袖套的减压,
          柔软的血管其动脉容积将急剧变化, 而硬的血管容积则缓慢地变化,
          可将这个曲线的倾斜程度加以数据化，数值越高表明上臂动脉硬化程度风险越高。
        </div>
        <div class="Api">
          <div class="targetItem" v-if="bloodPreData.Api">
            <div class="item1 item">
              <div class="item-t">
                <div
                  class="circle"
                  v-if="
                    returnCircle(
                      bloodPreData.Api.itemValue,
                      0,
                      ApiLevel[0],
                      ApiLevel[2] + 6,
                      0,
                      '最小'
                    )
                  "
                  :style="{
                    left: returnLeft(
                      bloodPreData.Api.itemValue,
                      0,
                      ApiLevel[0],
                      ApiLevel[2] + 6,
                      0,
                      164
                    ),
                  }"
                >
                  <div class="numItem">{{ bloodPreData.Api.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">正常</div>
            </div>
            <div class="item2 item">
              <div class="num">{{ ApiLevel[0] }}</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.Api.itemValue,
                      ApiLevel[0],
                      ApiLevel[1],
                      ApiLevel[2] + 6,
                      0,
                      164
                    ),
                  }"
                  v-if="
                    returnCircle(
                      bloodPreData.Api.itemValue,
                      ApiLevel[0],
                      ApiLevel[1],
                      ApiLevel[2] + 6,
                      0
                    )
                  "
                >
                  <div class="numItem">{{ bloodPreData.Api.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">轻度</div>
            </div>
            <div class="item4 item">
              <div class="num">{{ ApiLevel[1] }}</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.Api.itemValue,
                      ApiLevel[1],
                      ApiLevel[2],
                      ApiLevel[2] + 6,
                      0,
                      164
                    ),
                  }"
                  v-if="
                    returnCircle(
                      bloodPreData.Api.itemValue,
                      ApiLevel[1],
                      ApiLevel[2],
                      ApiLevel[2] + 6,
                      0
                    )
                  "
                >
                  <div class="numItem">{{ bloodPreData.Api.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">中度</div>
            </div>
            <div class="item5 item">
              <div class="num">{{ ApiLevel[2] }}</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.Api.itemValue,
                      ApiLevel[2],
                      ApiLevel[2] + 6,
                      ApiLevel[2] + 6,
                      0,
                      164
                    ),
                  }"
                  v-if="
                    returnCircle(
                      bloodPreData.Api.itemValue,
                      ApiLevel[2],
                      ApiLevel[2] + 6,
                      ApiLevel[2] + 6,
                      0,
                      '最大'
                    )
                  "
                >
                  <div class="numItem">{{ bloodPreData.Api.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">重度</div>
            </div>
          </div>
        </div>
        <div class="title">全部指标</div>
        <div class="bloodPreList oTable" v-if="bloodPre.examResultList">
          <div
            class="item"
            v-for="(item, index) in bloodPre.examResultList"
            :key="index"
            v-show="item.itemName != 'Age'"
          >
            <div class="item-l">
              {{
                hHDictionary[item.itemName]
                  ? hHDictionary[item.itemName].value
                  : item.itemName
              }}
            </div>
            <div class="item-c">
              {{ item.itemValue ? item.itemValue : "--" }}
            </div>
            <div class="item-r">
              <span v-if="item.itemName == 'Avi'"> {{ AviTxt }} </span>
              <span v-if="item.itemName == 'Api'"> {{ ApiTxt }} </span>
              <span v-if="item.itemName != 'Avi' && item.itemName != 'Api'">
                {{
                  item.itemName &&
                  hHDictionary[item.itemName] &&
                  hHDictionary[item.itemName].range
                    ? hHDictionary[item.itemName].range
                    : item.range
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="title">检测建议</div>
        <div class="advise">{{ bloodPreData.advise }}</div>
        <div class="title">血压</div>
        <div class="intro">
          血压指血液在血管内流动时作用于单位面积血管壁的侧压力，检查所测量的一般是体循环的动脉血压，包括收缩压与舒张压。
          血压正常代表人体心脏和血管都处于正常运转状态。
        </div>
        <div class="thirdItem">
          <div
            v-for="(item, index) in bloodPre.examResultList"
            :key="index"
            class="item"
            v-show="returnIsbloodPre(item.itemName)"
          >
            <div class="item-t">
              {{
                hHDictionary[item.itemName]
                  ? hHDictionary[item.itemName].value
                  : ""
              }}
            </div>
            <div :class="['item-b']">
              {{
                hHDictionary[item.itemName] &&
                hHDictionary[item.itemName].formatter
                  ? hHDictionary[item.itemName].formatter(item.itemValue)
                  : item.itemValue
              }}
              {{
                item.itemName && hHDictionary[item.itemName]
                  ? hHDictionary[item.itemName].unit
                  : ""
              }}
            </div>
          </div>
        </div>
        <div class="title">血压测量结果分布</div>
        <div class="High">
          <div class="targetItem" v-if="bloodPreData.High">
            <div class="item1 item">
              <div class="item-t">
                <div
                  class="circle"
                  v-if="
                    returnCircle(
                      bloodPreData.High.itemValue,
                      0,
                      90,
                      210,
                      0,
                      '最小'
                    )
                  "
                  :style="{
                    left: returnLeft(
                      bloodPreData.High.itemValue,
                      0,
                      90,
                      210,
                      0,
                      1.6
                    ),
                  }"
                >
                  <div class="numItem">{{ bloodPreData.High.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">偏低</div>
            </div>
            <div class="item2 item">
              <div class="num">90/60</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.High.itemValue,
                      90,
                      130,
                      199,
                      0,
                      1.6
                    ),
                  }"
                  v-if="
                    returnCircle(bloodPreData.High.itemValue, 90, 130, 199, 0)
                  "
                >
                  <div class="numItem">{{ bloodPreData.High.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">正常</div>
            </div>
            <div class="item3 item">
              <div class="num">130/85</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.High.itemValue,
                      130,
                      139,
                      210,
                      0,
                      2
                    ),
                  }"
                  v-if="
                    returnCircle(bloodPreData.High.itemValue, 130, 139, 210, 0)
                  "
                >
                  <div class="numItem">{{ bloodPreData.High.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">正常偏高</div>
            </div>
            <div class="item4 item">
              <div class="num">139/89</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.High.itemValue,
                      139,
                      159,
                      199,
                      0,
                      1.6
                    ),
                  }"
                  v-if="
                    returnCircle(bloodPreData.High.itemValue, 139, 159, 199, 0)
                  "
                >
                  <div class="numItem">{{ bloodPreData.High.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">轻度高血压</div>
            </div>
            <div class="item5 item">
              <div class="num">159/99</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.High.itemValue,
                      159,
                      179,
                      199,
                      0,
                      1.6
                    ),
                  }"
                  v-if="
                    returnCircle(
                      bloodPreData.High.itemValue,
                      159,
                      179,
                      199,
                      0,
                      '最大'
                    )
                  "
                >
                  <div class="numItem">{{ bloodPreData.High.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">中度高血压</div>
            </div>
            <div class="item6 item">
              <div class="num">179/109</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.High.itemValue,
                      179,
                      199,
                      199,
                      0,
                      1.6
                    ),
                  }"
                  v-if="
                    returnCircle(
                      bloodPreData.High.itemValue,
                      179,
                      199,
                      199,
                      0,
                      '最大'
                    )
                  "
                >
                  <div class="numItem">{{ bloodPreData.High.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">重度高血压</div>
            </div>
          </div>
        </div>
        <div class="title">心率测量结果分布</div>
        <div class="Heart">
          <div class="targetItem">
            <div class="item1 item">
              <div class="item-t">
                <div
                  class="circle"
                  v-if="
                    returnCircle(
                      bloodPreData.Heart.itemValue,
                      0,
                      60,
                      140,
                      0,
                      '最小'
                    )
                  "
                  :style="{
                    left: returnLeft(
                      bloodPreData.Heart.itemValue,
                      0,
                      60,
                      140,
                      0,
                      220
                    ),
                  }"
                >
                  <div class="numItem">{{ bloodPreData.Heart.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">偏低</div>
            </div>
            <div class="item3 item">
              <div class="num">60</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.Heart.itemValue,
                      60,
                      100,
                      140,
                      0,
                      220
                    ),
                  }"
                  v-if="
                    returnCircle(bloodPreData.Heart.itemValue, 60, 100, 140, 0)
                  "
                >
                  <div class="numItem">{{ bloodPreData.Heart.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">正常</div>
            </div>
            <div class="item5 item">
              <div class="num">100</div>
              <div class="item-t">
                <div
                  class="circle"
                  :style="{
                    left: returnLeft(
                      bloodPreData.Heart.itemValue,
                      100,
                      140,
                      140,
                      0,
                      220
                    ),
                  }"
                  v-if="
                    returnCircle(
                      bloodPreData.Heart.itemValue,
                      100,
                      140,
                      140,
                      0,
                      '最大'
                    )
                  "
                >
                  <div class="numItem">{{ bloodPreData.Heart.itemValue }}</div>
                </div>
              </div>
              <div class="item-b">偏高</div>
            </div>
          </div>
        </div>
        <div class="title">血压等级判断标准</div>
        <div class="bloodPreList oTable" v-if="bloodPre.examResultList">
          <div class="item" v-for="(item, index) in bloodPreGrade" :key="index">
            <div class="item-l">
              {{ item.value }}
            </div>
            <div class="item-c">
              {{ item.High ? item.High : "--" }}
            </div>
            <div class="item-r">
              {{ item.Low }}
            </div>
          </div>
        </div>
        <div class="careInfo">
          注：判断标准参考《中国高血压防治指南2018年修订版》，当收缩压和舒张压分属于不同级别时，以较高的分级为主
        </div>
        <div class="title">检测建议</div>
        <div class="advise">{{ bloodPreData.bloodAdvise }}</div>
      </div>
    </div>
    <div class="healthReport-c" v-if="oKey == boneMD.examItemCode">
      <div class="boneMD">
        <div class="title">骨密度检测</div>
        <div class="sexAge">
          <div class="sexAge-l" v-if="reportListData.data">
            性别：{{ reportListData.data.gender == "M" ? "男" : "女" }}
          </div>
          <div class="sexAge-l">年龄：{{ reportListData.data.age }}</div>
        </div>
        <div class="time">*检测时间：{{ boneMD.createTime }}</div>
        <div class="intro">
          骨密度是指骨单位体积或者是单位面积所含的骨矿物量，它是反映骨骼强度的重要指标，
          还是用来预测患者发生骨折可能性的重要依据。
        </div>
        <div class="title">T值</div>
        <div class="intro">
          骨质量与同性别20岁以上成人平均水平的比值，是骨密度评估的主要参数。
        </div>
        <div class="targetItem" v-if="boneMDData.Tscore">
          <div class="item1 item">
            <div class="item-t">
              <div
                class="circle"
                v-if="
                  returnCircle(
                    boneMDData.Tscore.itemValue,
                    -4,
                    -2.5,
                    4,
                    -4,
                    '最小'
                  )
                "
                :style="{
                  left: returnLeft(
                    boneMDData.Tscore.itemValue,
                    -4,
                    -2.5,
                    4,
                    -4,
                    220
                  ),
                }"
              >
                <div class="numItem">{{ boneMDData.Tscore.itemValue }}</div>
              </div>
            </div>
            <div class="item-b">骨质疏松</div>
          </div>
          <div class="item3 item">
            <div class="num">-2.5</div>
            <div class="item-t">
              <div
                class="circle"
                :style="{
                  left: returnLeft(
                    boneMDData.Tscore.itemValue,
                    -2.5,
                    -1,
                    4,
                    -4,
                    220
                  ),
                }"
                v-if="
                  returnCircle(boneMDData.Tscore.itemValue, -2.5, -1, 4, -4)
                "
              >
                <div class="numItem">{{ boneMDData.Tscore.itemValue }}</div>
              </div>
            </div>
            <div class="item-b">骨量减少</div>
          </div>
          <div class="item5 item">
            <div class="num">-1.0</div>
            <div class="item-t">
              <div
                class="circle"
                :style="{
                  left: returnLeft(
                    boneMDData.Tscore.itemValue,
                    -1,
                    4,
                    4,
                    -4,
                    220
                  ),
                }"
                v-if="
                  returnCircle(
                    boneMDData.Tscore.itemValue,
                    -1,
                    4,
                    4,
                    -4,
                    '最大'
                  )
                "
              >
                <div class="numItem">{{ boneMDData.Tscore.itemValue }}</div>
              </div>
            </div>
            <div class="item-b">正常</div>
          </div>
        </div>
        <div class="title">骨密度等级判断标准</div>
        <div class="oTable" v-if="boneMD.examResultList">
          <div
            class="item"
            v-for="(item, index) in boneMD.examResultList"
            :key="index"
          >
            <div class="item-l">
              {{
                hHDictionary[item.itemName]
                  ? hHDictionary[item.itemName].value
                  : item.itemName
              }}
            </div>
            <div class="item-r">
              {{
                hHDictionary[item.itemName] &&
                hHDictionary[item.itemName].formatter
                  ? hHDictionary[item.itemName].formatter(item.itemValue)
                  : item.itemValue
              }}
            </div>
          </div>
        </div>
        <div class="title">检测建议</div>
        <div class="advise">
          {{ boneMDData.advise }}
        </div>
      </div>
    </div>
    <div class="healthReport-b"></div>
  </div>
</template>

<script>
import { getExamDetailUrl } from "./api";
import { getHashParam } from "@/utils/utils.js";
import { hHDictionary } from "../dictionary";
import { Bmidvise } from "./advise";
import { bloodPreGrade } from "./bloodPreGrade";
import { drawBarChart } from "../echart/index";
import { mapState } from "vuex";
import {
  bmiAdArrMap,
  bmiArrMap,
  tizhilvArrMap,
  bodyAgeArrMap,
  bodyScoreArrMap,
  neizangArrMap,
} from "./map";
import {
  aviArr,
  aviAdvice,
  apiArr,
  boneMDAdvice,
  apiAdvice,
  bloodPreAdvice,
} from "./aviMap";
export default {
  name: "healthReport",
  data() {
    return {
      bmiArrMap,
      AviLevel: [],
      ApiLevel: [],
      bloodPreGrade,
      hHDictionary,
      examId: "",
      oKey: 1,
      titleList: [],
      reportListData: {
        data: {},
      },
      boneMD: "",
      bloodPre: "",
      peopleComponent: "",
      heightWeight: "",
      BMI: "",
      heightWeightData: {
        Bmi: "",
        advise: "",
      },
      boneMDData: {
        Tscore: "",
        advise: "",
      },
      bloodPreData: {
        Api: "",
        Avi: "",
        High: "",
        Low: "",
        Heart: "",
        advise: "",
        bloodAdvise: "",
      },
      peopleComponentData: {
        BodyFatRating: "",
        LeftHandMuscle: "",
        RightHandMuscle: "",
        LeftFootMuscle: "",
        RightFootMuscle: "",
        BodyMuscleMass: "",
        Bmi: "",
        Weight: "",
        MuscleMass: "",
        NoFatWeight: "",
        FatWeight: "",
        BoneWeight: "",
        BodyWater: "",
        danbai: "",
        advise: "",
      },
      peopleComponentList: [],
      advise: {},
      AviTxt: "",
      ApiTxt: "",
      AviRange: [],
      ApiRange: [],
    };
  },
  created() {
    this.examId = this.$route.query.examId;
  },
  computed: {
    ...mapState(["device", "userInfo"]),
  },
  mounted() {
    let age;
    if (this.userInfo.age) {
      age = this.userInfo.age;
    }
    if (this.getRange(age, aviArr)) {
      this.AviLevel = this.getRange(age, aviArr).split(",");
    }
    if (this.getRange(age, apiArr)) {
      this.ApiLevel = this.getRange(age, apiArr).split(",");
    }
    console.log(hHDictionary);
    this.getExamDetail();
  },
  methods: {
    checkbodyAgeLevel(data) {
      var level = 1;
      //年龄
      let age = this.reportListData.data.age;
      if (data <= age) {
        level = 1;
      } else {
        level = 2;
      }
      return level;
    },
    checkNeiZangLevel(data) {
      var level = 1;
      //
      if (data < 10) {
        level = 2;
      } else if (data >= 10 && data < 15) {
        level = 3;
      } else {
        level = 4;
      }
      return level;
    },
    checkDXLLevel(data) {
      var level = 1;
      //年龄
      let age = this.reportListData.data.age;
      //M 男
      let gender = this.reportListData.data.gender;
      if (gender == "M") {
        if (age < 30) {
          if (data < 1550) {
            level = 1;
          } else {
            level = 2;
          }
        } else if (age >= 30 && age < 50) {
          if (data < 1500) {
            level = 1;
          } else {
            level = 2;
          }
        } else if (age >= 50 && age < 70) {
          if (data < 1350) {
            level = 1;
          } else {
            level = 2;
          }
        } else {
          if (data < 1220) {
            level = 1;
          } else {
            level = 2;
          }
        }
      } else {
        if (age < 30) {
          if (data < 1210) {
            level = 1;
          } else {
            level = 2;
          }
        } else if (age >= 30 && age < 50) {
          if (data < 1170) {
            level = 1;
          } else {
            level = 2;
          }
        } else if (age >= 50 && age < 70) {
          if (data < 1110) {
            level = 1;
          } else {
            level = 2;
          }
        } else {
          if (data < 1010) {
            level = 1;
          } else {
            level = 2;
          }
        }
      }
      return level;
    },
    checkZfLevel(data) {
      var level = 1;
      //年龄
      let age = this.reportListData.data.age;
      //M 男
      let gender = this.reportListData.data.gender;
      if (gender == "M") {
        if (age < 40) {
          if (data < 0.11) {
            level = 1;
          } else if (data >= 0.11 && data < 0.22) {
            level = 2;
          } else if (data >= 0.22 && data < 0.25) {
            level = 3;
          } else {
            level = 4;
          }
        } else if (age >= 40 && age < 60) {
          if (data < 0.12) {
            level = 1;
          } else if (data >= 0.12 && data < 0.23) {
            level = 2;
          } else if (data >= 0.23 && data < 0.284) {
            level = 3;
          } else {
            level = 4;
          }
        } else {
          if (data < 0.14) {
            level = 1;
          } else if (data >= 0.14 && data < 0.25) {
            level = 2;
          } else if (data >= 0.25 && data < 0.3) {
            level = 3;
          } else {
            level = 4;
          }
        }
      } else {
        if (age < 40) {
          if (data < 0.21) {
            level = 1;
          } else if (data >= 0.21 && data < 0.35) {
            level = 2;
          } else if (data >= 0.35 && data < 0.396) {
            level = 3;
          } else {
            level = 4;
          }
        } else if (age >= 40 && age < 60) {
          if (data < 0.23) {
            level = 1;
          } else if (data >= 0.23 && data < 0.36) {
            level = 2;
          } else if (data >= 0.36 && data < 0.4) {
            level = 3;
          } else {
            level = 4;
          }
        } else {
          if (data < 0.24) {
            level = 1;
          } else if (data >= 0.24 && data < 0.37) {
            level = 2;
          } else if (data >= 0.37 && data < 0.414) {
            level = 3;
          } else {
            level = 4;
          }
        }
      }
      return level;
    },
    checkDBZLLevel(data) {
      var level = 1;
      //年龄
      let age = this.reportListData.data.age;
      //M 男
      let gender = this.reportListData.data.gender;
      if (gender == "M") {
        if (age < 61) {
          if (data < 16) {
            level = 1;
          } else if (data >= 16 && data < 18) {
            level = 2;
          } else {
            level = 3;
          }
        } else {
          if (data < 15) {
            level = 1;
          } else if (data >= 15 && data < 17) {
            level = 2;
          } else {
            level = 3;
          }
        }
      } else {
        if (age < 61) {
          if (data < 14) {
            level = 1;
          } else if (data >= 14 && data < 16) {
            level = 2;
          } else {
            level = 3;
          }
        } else {
          if (data < 13) {
            level = 1;
          } else if (data >= 13 && data < 15) {
            level = 2;
          } else {
            level = 3;
          }
        }
      }
      return level;
    },
    checkWaterLevel(data) {
      var level = 1;
      //M 男
      let gender = this.reportListData.data.gender;
      if (gender == "M") {
        if (data < 55) {
          level = 1;
        } else if (data >= 55 && data < 65) {
          level = 2;
        } else {
          level = 3;
        }
      } else {
        if (data < 45) {
          level = 1;
        } else if (data >= 45 && data < 60) {
          level = 2;
        } else {
          level = 3;
        }
      }
      return level;
    },
    checkGUliangLevel(data) {
      var level = 1;
      //体重
      let weight = "";
      if (this.peopleComponentData.Weight) {
        weight = this.peopleComponentData.Weight.itemValue;
      }
      //M 男
      let gender = this.reportListData.data.gender;
      if (gender == "M") {
        if (weight < 60) {
          if (data < 2.5) {
            level = 1;
          } else {
            level = 2;
          }
        } else if (weight >= 60 && weight < 75) {
          if (data < 2.9) {
            level = 1;
          } else {
            level = 2;
          }
        } else {
          if (data < 3.2) {
            level = 1;
          } else {
            level = 2;
          }
        }
      } else {
        if (weight < 45) {
          if (data < 1.8) {
            level = 1;
          } else {
            level = 2;
          }
        } else if (weight >= 45 && weight < 60) {
          if (data < 2.2) {
            level = 1;
          } else {
            level = 2;
          }
        } else {
          if (data < 2.5) {
            level = 1;
          } else {
            level = 2;
          }
        }
      }
      return level;
    },
    checkBoneGLevel(data) {
      var level = 1;
      if (data < 0.4) {
        level = 1;
      } else {
        level = 2;
      }
      return level;
    },
    checkBoneLevel(data) {
      var level = 1;
      if (data >= -1) {
        level = 1;
      } else if (data >= -2.5 && data < -1) {
        level = 2;
      } else {
        level = 3;
      }
      return level;
    },
    checkBloodLevel(Low, High) {
      var level = 1;
      if (Low <= 60 || High <= 90) {
        level = 1;
      } else if (Low <= 85 || High <= 130) {
        level = 2;
      } else if (Low <= 89 || High <= 139) {
        level = 3;
      } else if (Low <= 99 || High <= 159) {
        level = 4;
      } else if (Low <= 109 || High <= 179) {
        level = 5;
      } else {
        level = 6;
      }
      return level;
    },
    checkJrLevel(data) {
      var level = 1;
      //身高
      let height = this.reportListData.data.height;
      //M 男
      let gender = this.reportListData.data.gender;
      if (gender == "M") {
        if (height < 160) {
          if (data < 38.5) {
            level = 1;
          } else if (data >= 38.5 && data < 46.6) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (height >= 160 && height < 170) {
          if (data < 44) {
            level = 1;
          } else if (data >= 44 && data < 52.5) {
            level = 2;
          } else {
            level = 3;
          }
        } else {
          if (data < 49.4) {
            level = 1;
          } else if (data >= 49.4 && data < 59.5) {
            level = 2;
          } else {
            level = 3;
          }
        }
      } else {
        if (height < 150) {
          if (data < 29.1) {
            level = 1;
          } else if (data >= 29.1 && data < 34.8) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (height >= 150 && height < 160) {
          if (data < 32.9) {
            level = 1;
          } else if (data >= 32.9 && data < 37.6) {
            level = 2;
          } else {
            level = 3;
          }
        } else {
          if (data < 36.5) {
            level = 1;
          } else if (data >= 36.5 && data < 42.6) {
            level = 2;
          } else {
            level = 3;
          }
        }
      }
      return level;
    },
    checkJRLLevel(data) {
      var level = 1;
      //年龄
      let age = this.reportListData.data.age;
      //M 男
      let gender = this.reportListData.data.gender;
      if (gender == "M") {
        if (age < 20) {
          if (data < 0.73) {
            level = 1;
          } else if (data >= 0.73 && data < 0.831) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (age >= 20 && age < 30) {
          if (data < 0.72) {
            level = 1;
          } else if (data >= 0.72 && data < 0.821) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (age >= 30 && age < 40) {
          if (data < 0.71) {
            level = 1;
          } else if (data >= 0.71 && data < 0.811) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (age >= 40 && age < 50) {
          if (data < 0.7) {
            level = 1;
          } else if (data >= 0.71 && data < 0.801) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (age >= 50 && age < 61) {
          if (data < 0.69) {
            level = 1;
          } else if (data >= 0.69 && data < 0.791) {
            level = 2;
          } else {
            level = 3;
          }
        } else {
          if (data < 0.66) {
            level = 1;
          } else if (data >= 0.66 && data < 0.791) {
            level = 2;
          } else {
            level = 3;
          }
        }
      } else {
        if (age < 20) {
          if (data < 0.67) {
            level = 1;
          } else if (data >= 0.67 && data < 0.771) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (age >= 20 && age < 30) {
          if (data < 0.66) {
            level = 1;
          } else if (data >= 0.66 && data < 0.761) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (age >= 30 && age < 40) {
          if (data < 0.65) {
            level = 1;
          } else if (data >= 0.65 && data < 0.751) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (age >= 40 && age < 50) {
          if (data < 0.64) {
            level = 1;
          } else if (data >= 0.64 && data < 0.741) {
            level = 2;
          } else {
            level = 3;
          }
        } else if (age >= 50 && age < 61) {
          if (data < 0.63) {
            level = 1;
          } else if (data >= 0.63 && data < 0.731) {
            level = 2;
          } else {
            level = 3;
          }
        } else {
          if (data < 0.62) {
            level = 1;
          } else if (data >= 0.62 && data < 0.731) {
            level = 2;
          } else {
            level = 3;
          }
        }
      }
      return level;
    },

    checkBmiLevel(BMI) {
      var level = 1;
      if (BMI < 18.5) {
        level = 1;
      } else if (BMI >= 18.5 && BMI < 24.0) {
        level = 2;
      } else if (BMI >= 24.0 && BMI < 28.0) {
        level = 3;
      } else {
        level = 4;
      }
      return level;
    },
    checkLevel(avi, oLevel) {
      let age = this.age;
      var level = 1;
      var aa = oLevel;
      var spot1 = parseFloat(aa[0]);
      var spot2 = parseFloat(aa[1]);
      var spot3 = parseFloat(aa[2]);
      avi = avi * 1;
      if (avi <= spot1) {
        level = 1;
      } else if (avi > spot1 && avi <= spot2) {
        level = 2;
      } else if (avi > spot2 && avi <= spot3) {
        level = 3;
      } else if (avi > spot3) {
        level = 4;
      }
      return level;
    },
    getRange(age, Arr) {
      var Ranges = Arr;
      if (age < 1) {
        age = 1;
      }
      if (age > 80) {
        age = 80;
      }
      return Ranges[age - 1];
    },
    returnIsbloodPre(data) {
      let cuerrtArr = ["High", "Low", "Heart"];
      if (cuerrtArr.indexOf(data) != -1) {
        return true;
      } else {
        return false;
      }
    },
    judgeFat() {
      let bmi = this.peopleComponentData.Bmi.itemValue;
      if (bmi >= 0 && bmi < 18) {
        return 1;
      } else if (bmi >= 18 && bmi < 24) {
        return 2;
      } else if (bmi >= 24 && bmi < 30) {
        return 3;
      } else if (bmi >= 30 && bmi < 36) {
        return 4;
      } else if (bmi >= 36) {
        return 5;
      }
    },
    returnComs(data) {
      let cuerrtArr = [
        "Bmi",
        "FatPercentage",
        "BodyWaterPercentage",
        "BoneWeight",
        "FatWeight",
        "PhysicalAge",
        "MuscleMass",
        "BasalMetabolismKj",
      ];
      if (cuerrtArr.indexOf(data) != -1) {
        return true;
      } else {
        return false;
      }
    },
    returnIsCom(data) {
      let cuerrtArr = ["FatPercentage", "MuscleMass", "BodyWaterPercentage"];
      if (cuerrtArr.indexOf(data) != -1) {
        return true;
      } else {
        return false;
      }
    },
    returnLeft(nowData, begin, end, max, min, width, isF) {
      let oss = document.body.clientWidth / 750;
      let oWidth = 130;
      if (width) {
        oWidth = oss * width;
      }
      if (nowData) {
        if (Number(nowData) > begin && Number(nowData) <= end) {
          let chaData =
            (((nowData - begin) / (end - begin)) * oWidth).toFixed(2) + "px";
          return chaData;
        } else {
          if (Number(nowData) > max) {
            if (isF == 1) {
              return 0;
            } else {
              return oWidth + "px";
            }
          }
          if (Number(nowData) < min) {
            if (isF == 1) {
              return oWidth + "px";
            } else {
              return 0;
            }
          }
          return null;
        }
      }
    },
    returnCircle(nowData, begin, end, max, min, sign) {
      if (nowData) {
        if (Number(nowData) > begin && Number(nowData) <= end) {
          return true;
        } else {
          if (Number(nowData) > max) {
            if (sign == "最大") {
              return true;
            }
          }
          if (Number(nowData) < min) {
            if (sign == "最小") {
              return true;
            }
          }
          return false;
        }
      }
    },
    initChart() {
      try {
        if (this.oKey == 2) {
          let WeightArr;
          let nofat;
          if (
            this.peopleComponentData.Weight.itemValue &&
            this.peopleComponentData.FatWeight.itemValue
          ) {
            nofat = (
              Number(this.peopleComponentData.Weight.itemValue) -
              Number(this.peopleComponentData.FatWeight.itemValue)
            ).toFixed(1);
            this.peopleComponentData.NoFatWeight = {};
            this.peopleComponentData.NoFatWeight.itemValue = nofat;
            WeightArr = [
              {
                name: "去脂体重",
                value: nofat,
                itemStyle: {
                  color: "rgba(134, 190, 253, 1)",
                },
              },
              {
                name: "脂肪",
                itemStyle: {
                  color: "rgba(134, 190, 253, 0.3)",
                },
                value: Number(this.peopleComponentData.FatWeight.itemValue),
              },
            ];
            this.$nextTick(() => {
              drawBarChart(
                "Weight",
                "",
                WeightArr,
                "体重",
                this.peopleComponentData.Weight.itemValue + "kg"
              );
            });
          }
          if (
            this.peopleComponentData.MuscleMass.itemValue &&
            this.peopleComponentData.BoneWeight.itemValue
          ) {
            let NoFatArr;
            NoFatArr = [
              {
                name: "骨量",
                value: this.peopleComponentData.BoneWeight.itemValue,
                itemStyle: {
                  color: "rgba(150, 216, 201, 0.3)",
                },
              },
              {
                name: "肌肉",
                itemStyle: {
                  color: "rgba(150, 216, 201, 1)",
                },
                value: this.peopleComponentData.MuscleMass.itemValue,
              },
            ];
            this.$nextTick(() => {
              drawBarChart("NoFat", "", NoFatArr, "去脂体重", nofat + "kg");
            });
          }

          if (
            this.peopleComponentData.MuscleMass.itemValue &&
            this.peopleComponentData.BodyWater.itemValue
          ) {
            let MuscleMassArr;
            let danbai = (
              Number(this.peopleComponentData.MuscleMass.itemValue) -
              Number(this.peopleComponentData.BodyWater.itemValue)
            ).toFixed(1);
            this.peopleComponentData.danbai = {};
            this.peopleComponentData.danbai["itemValue"] = danbai;
            MuscleMassArr = [
              {
                name: "蛋白质",
                value: danbai,
                itemStyle: {
                  color: "rgba(252, 215, 115, 0.3)",
                },
              },
              {
                name: "水分",
                itemStyle: {
                  color: "rgba(252, 215, 115, 1)",
                },
                value: Number(
                  this.peopleComponentData.BodyWater.itemValue
                ).toFixed(1),
              },
            ];

            this.$nextTick(() => {
              drawBarChart(
                "MuscleMass",
                "",
                MuscleMassArr,
                "肌肉",
                this.peopleComponentData.MuscleMass.itemValue + "kg"
              );
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectTitle(item) {
      this.oKey = item.key;
      console.log(this.peopleComponentData, "34");
      try {
        if (this.oKey == 2) {
          let WeightArr;
          let nofat;
          if (
            this.peopleComponentData.Weight.itemValue &&
            this.peopleComponentData.FatWeight.itemValue
          ) {
            nofat = (
              Number(this.peopleComponentData.Weight.itemValue) -
              Number(this.peopleComponentData.FatWeight.itemValue)
            ).toFixed(1);
            this.peopleComponentData.NoFatWeight = {};
            this.peopleComponentData.NoFatWeight.itemValue = nofat;
            WeightArr = [
              {
                name: "去脂体重",
                value: nofat,
                itemStyle: {
                  color: "rgba(134, 190, 253, 1)",
                },
              },
              {
                name: "脂肪",
                itemStyle: {
                  color: "rgba(134, 190, 253, 0.3)",
                },
                value: Number(this.peopleComponentData.FatWeight.itemValue),
              },
            ];
            this.$nextTick(() => {
              drawBarChart(
                "Weight",
                "",
                WeightArr,
                "体重",
                this.peopleComponentData.Weight.itemValue + "kg"
              );
            });
          }
          if (
            this.peopleComponentData.MuscleMass.itemValue &&
            this.peopleComponentData.BoneWeight.itemValue
          ) {
            let NoFatArr;
            NoFatArr = [
              {
                name: "骨量",
                value: this.peopleComponentData.BoneWeight.itemValue,
                itemStyle: {
                  color: "rgba(150, 216, 201, 0.3)",
                },
              },
              {
                name: "肌肉",
                itemStyle: {
                  color: "rgba(150, 216, 201, 1)",
                },
                value: this.peopleComponentData.MuscleMass.itemValue,
              },
            ];
            this.$nextTick(() => {
              drawBarChart("NoFat", "", NoFatArr, "去脂体重", nofat + "kg");
            });
          }

          if (
            this.peopleComponentData.MuscleMass.itemValue &&
            this.peopleComponentData.BodyWater.itemValue
          ) {
            let MuscleMassArr;
            let danbai = (
              Number(this.peopleComponentData.MuscleMass.itemValue) -
              Number(this.peopleComponentData.BodyWater.itemValue)
            ).toFixed(1);
            this.peopleComponentData.danbai = {};
            this.peopleComponentData.danbai["itemValue"] = danbai;
            MuscleMassArr = [
              {
                name: "蛋白质",
                value: danbai,
                itemStyle: {
                  color: "rgba(252, 215, 115, 0.3)",
                },
              },
              {
                name: "水分",
                itemStyle: {
                  color: "rgba(252, 215, 115, 1)",
                },
                value: Number(
                  this.peopleComponentData.BodyWater.itemValue
                ).toFixed(1),
              },
            ];

            this.$nextTick(() => {
              drawBarChart(
                "MuscleMass",
                "",
                MuscleMassArr,
                "肌肉",
                this.peopleComponentData.MuscleMass.itemValue + "kg"
              );
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkRange(data, arr) {
      var reference = [];
      if (data <= Number(arr[0])) {
        reference = ["", arr[0]];
      } else if (data > Number(arr[0]) && data <= Number(arr[1])) {
        reference = [arr[0], arr[1]];
      } else if (data > Number(arr[1]) && data <= Number(arr[2])) {
        reference = [arr[1], arr[2]];
      } else {
        reference = [arr[2], ""];
      }
      return reference;
    },
    async getExamDetail() {
      let params = {
        examId: this.examId,
      };
      let res = await this.$axios.get(`${getExamDetailUrl}`, { params });
      if (res.code == 200) {
        this.reportListData.data = res.data;
        if (this.reportListData.data.examDetailList) {
          this.reportListData.data.examDetailList.forEach((element) => {
            let obj = {
              key: element.examItemCode,
              value: this.device[element.examItemCode].testProName,
            };
            this.titleList.push(obj);
            if (
              this.device[element.examItemCode] &&
              this.device[element.examItemCode].testProName == "身高体重"
            ) {
              this.heightWeight = element;
            }
            if (
              this.device[element.examItemCode] &&
              this.device[element.examItemCode].testProName == "人体成分"
            ) {
              this.peopleComponent = element;
            }
            if (
              this.device[element.examItemCode] &&
              this.device[element.examItemCode].testProName == "骨密度"
            ) {
              this.boneMD = element;
            }
            if (
              this.device[element.examItemCode] &&
              this.device[element.examItemCode].testProName == "动脉硬化血压"
            ) {
              this.bloodPre = element;
            }
          });
          if (getHashParam("oKey")) {
            this.oKey = getHashParam("oKey");
          } else {
            this.oKey = this.titleList[0] ? this.titleList[0].key : "";
          }

          if (this.bloodPre.examResultList) {
            this.bloodPre.examResultList.unshift({
              itemName: "指标名称",
              itemValue: "测量值",
              range: "参考值",
            });
            for (var i in this.bloodPreData) {
              this.bloodPre.examResultList.forEach((ele) => {
                if (ele.itemName == i) {
                  this.bloodPreData[i] = ele;
                }
              });
            }
            if (this.bloodPreData.Avi && this.bloodPreData.Avi.itemValue) {
              this.bloodPreData.advise =
                aviAdvice[
                  this.checkLevel(
                    this.bloodPreData.Avi.itemValue,
                    this.AviLevel
                  )
                ];
              this.AviRange = this.checkRange(
                this.bloodPreData.Avi.itemValue,
                this.AviLevel
              );
              if (this.AviRange[0]) {
                if (this.AviRange[1]) {
                  this.AviTxt = this.AviRange[0] + "~" + this.AviRange[1];
                } else {
                  this.AviTxt = ">=" + this.AviRange[0];
                }
              } else {
                this.AviTxt = "<=" + this.AviRange[1];
              }
            }
            if (this.bloodPreData.Api && this.bloodPreData.Api.itemValue) {
              this.ApiRange = this.checkRange(
                this.bloodPreData.Api.itemValue,
                this.ApiLevel
              );
              if (this.ApiRange[0]) {
                if (this.ApiRange[1]) {
                  this.ApiTxt = this.ApiRange[0] + "~" + this.ApiRange[1];
                } else {
                  this.ApiTxt = ">=" + this.ApiRange[0];
                }
              } else {
                this.ApiTxt = "<=" + this.ApiRange[1];
              }
            }
            if (
              this.bloodPreData.High &&
              this.bloodPreData.High.itemValue &&
              this.bloodPreData.Low.itemValue &&
              this.bloodPreData.Low
            ) {
              this.bloodPreData.bloodAdvise =
                bloodPreAdvice[
                  this.checkBloodLevel(
                    this.bloodPreData.Low.itemValue,
                    this.bloodPreData.High.itemValue
                  )
                ];
            }
          }
          if (this.peopleComponent.examResultList) {
            for (var j in this.peopleComponentData) {
              this.peopleComponent.examResultList.forEach((ele) => {
                if (ele.itemName == j) {
                  this.peopleComponentData[j] = ele;
                }
              });
            }
            let cuerrtArr = [
              "Bmi",
              "FatPercentage",
              "BodyFatRating",
              "BodyWaterPercentage",
              "BoneWeight",
              "FatWeight",
              "PhysicalAge",
              "MuscleMass",
              "BasalMetabolismKj",
            ];
            cuerrtArr.forEach((element) => {
              this.peopleComponent.examResultList.forEach((ele) => {
                if (ele.itemName == element) {
                  ele.extra = "";
                  ele.class = "";
                  this.peopleComponentList.push(ele);
                }
              });
            });
            //蛋白
            if (
              this.peopleComponentData.MuscleMass.itemValue &&
              this.peopleComponentData.BodyWater.itemValue
            ) {
              let danbai = (
                Number(this.peopleComponentData.MuscleMass.itemValue) -
                Number(this.peopleComponentData.BodyWater.itemValue)
              ).toFixed(1);
              let obj = {
                itemName: "danbai",
                itemUnit: "",
                extra: "",
                class: "",
                itemValue: danbai,
              };
              this.peopleComponentList.push(obj);
            }
            //肌肉率
            if (
              this.peopleComponentData.MuscleMass.itemValue &&
              this.peopleComponentData.Weight.itemValue
            ) {
              let jiroulv = (
                (Number(this.peopleComponentData.MuscleMass.itemValue) /
                  Number(this.peopleComponentData.Weight.itemValue)) *
                100
              ).toFixed(1);
              let obj = {
                itemName: "jiroulv",
                itemUnit: "",
                extra: "",
                class: "",
                itemValue: jiroulv,
              };
              this.peopleComponentList.push(obj);
            }
            //骨骼率
            if (
              this.peopleComponentData.LeftHandMuscle.itemValue &&
              this.peopleComponentData.RightHandMuscle.itemValue &&
              this.peopleComponentData.LeftFootMuscle.itemValue &&
              this.peopleComponentData.RightFootMuscle.itemValue &&
              this.peopleComponentData.BodyMuscleMass.itemValue &&
              this.peopleComponentData.Weight.itemValue
            ) {
              let gugeji = (
                ((Number(this.peopleComponentData.LeftHandMuscle.itemValue) +
                  Number(this.peopleComponentData.RightHandMuscle.itemValue) +
                  Number(this.peopleComponentData.LeftFootMuscle.itemValue) +
                  Number(this.peopleComponentData.RightFootMuscle.itemValue) +
                  Number(this.peopleComponentData.BodyMuscleMass.itemValue)) /
                  Number(this.peopleComponentData.Weight.itemValue)) *
                100
              ).toFixed(1);
              let obj = {
                itemName: "gugeji",
                itemUnit: "",
                extra: "",
                class: "",
                itemValue: gugeji,
              };
              this.peopleComponentList.push(obj);
            }
            let FatWeightObj = {
              class: "",
              extra: "",
            };
            this.peopleComponentList.forEach((ele) => {
              if (ele.itemName == "Bmi") {
                ele.class = "oActive" + this.checkBmiLevel(ele.itemValue);
                ele.extra = bmiArrMap[this.checkBmiLevel(ele.itemValue)];
              }
              if (ele.itemName == "FatPercentage") {
                ele.class = "oActive" + this.checkZfLevel(ele.itemValue / 100);
                ele.extra =
                  tizhilvArrMap[this.checkZfLevel(ele.itemValue / 100)];
                FatWeightObj.class = ele.class;
                FatWeightObj.extra = ele.extra;
              }
              if (ele.itemName == "MuscleMass") {
                ele.class = "oActive" + this.checkJrLevel(ele.itemValue);
                ele.extra = tizhilvArrMap[this.checkJrLevel(ele.itemValue)];
              }
              if (ele.itemName == "jiroulv") {
                ele.class = "oActive" + this.checkJRLLevel(ele.itemValue);
                ele.extra = tizhilvArrMap[this.checkJRLLevel(ele.itemValue)];
              }
              if (ele.itemName == "gugeji") {
                ele.class =
                  "oActive" + this.checkBoneGLevel(ele.itemValue / 100);
                ele.extra =
                  tizhilvArrMap[this.checkBoneGLevel(ele.itemValue / 100)];
              }
              if (ele.itemName == "BoneWeight") {
                ele.class = "oActive" + this.checkGUliangLevel(ele.itemValue);
                ele.extra =
                  tizhilvArrMap[this.checkGUliangLevel(ele.itemValue)];
              }
              if (ele.itemName == "BodyFatRating") {
                ele.class = "oActive" + this.checkNeiZangLevel(ele.itemValue);
                ele.extra =
                  neizangArrMap[this.checkNeiZangLevel(ele.itemValue)];
              }
              if (ele.itemName == "BodyWaterPercentage") {
                ele.class = "oActive" + this.checkWaterLevel(ele.itemValue);
                ele.extra = tizhilvArrMap[this.checkWaterLevel(ele.itemValue)];
              }
              if (ele.itemName == "danbai") {
                ele.class = "oActive" + this.checkDBZLLevel(ele.itemValue);
                ele.extra = tizhilvArrMap[this.checkDBZLLevel(ele.itemValue)];
              }
              if (ele.itemName == "BasalMetabolismKj") {
                ele.class = "oActive" + this.checkDXLLevel(ele.itemValue);
                ele.extra = tizhilvArrMap[this.checkDXLLevel(ele.itemValue)];
              }
              if (ele.itemName == "PhysicalAge") {
                ele.class = "oActive" + this.checkbodyAgeLevel(ele.itemValue);
                ele.extra =
                  bodyAgeArrMap[this.checkbodyAgeLevel(ele.itemValue)];
              }
            });
            this.peopleComponentList.forEach((ele) => {
              if (ele.itemName == "FatWeight") {
                ele.class = FatWeightObj.class;
                ele.extra = FatWeightObj.extra;
              }
            });

            console.log(this.peopleComponentList, "09");
            if (
              this.peopleComponentData.Bmi &&
              this.peopleComponentData.Bmi.itemValue
            ) {
              this.peopleComponentData.advise =
                bmiAdArrMap[
                  this.checkBmiLevel(this.peopleComponentData.Bmi.itemValue)
                ];
            }
          }

          if (this.heightWeight.examResultList) {
            for (var p in this.heightWeightData) {
              this.heightWeight.examResultList.forEach((ele) => {
                if (ele.itemName == p) {
                  this.heightWeightData[p] = ele;
                }
              });
            }
            if (
              this.heightWeightData.Bmi &&
              this.heightWeightData.Bmi.itemValue
            ) {
              this.heightWeightData.advise =
                bmiAdArrMap[
                  this.checkBmiLevel(this.heightWeightData.Bmi.itemValue)
                ];
            }
          }
          if (this.boneMD.examResultList) {
            console.log(this.boneMD.examResultList, "9888");
            this.boneMD.examResultList.unshift({
              itemName: "名称",
              itemValue: "检测值",
            });
            for (var o in this.boneMDData) {
              this.boneMD.examResultList.forEach((ele) => {
                if (ele.itemName == o) {
                  this.boneMDData[o] = ele;
                }
              });
            }
            if (this.boneMDData.Tscore && this.boneMDData.Tscore.itemValue) {
              this.boneMDData.advise =
                boneMDAdvice[
                  this.checkBoneLevel(this.boneMDData.Tscore.itemValue)
                ];
            }
          }
          this.initChart();
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.healthReport {
  height: 100%;
  overflow-y: auto;
  background: rgba(239, 247, 248, 1);
  padding: 118px 0 80px;
  box-sizing: border-box;
  position: relative;
  .oActive {
    padding: 4px 12px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    border-radius: 6px;
    display: inline-block;
  }
  .oActive1 {
    background: rgba(130, 202, 255, 1);
  }
  .oActive2 {
    background: rgba(36, 182, 83, 1);
  }
  .oActive3 {
    background: rgba(36, 182, 83, 1);
  }
  .oActive4 {
    background: rgba(224, 32, 32, 1);
  }
  .oActive5 {
    background: rgba(224, 32, 32, 1);
  }
  .oActive6 {
    background: rgba(224, 32, 32, 1);
  }
  .healthReport-t {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    background: #fff;
    padding: 28px 50px;
    box-sizing: border-box;
    justify-content: space-around;
    display: flex;
    left: 0;
    .item {
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
    }
    .active {
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 26px;
        height: 26px;
        background: url("./img/active.png") no-repeat;
        background-size: 100% 100%;
        top: -18px;
        right: -18px;
      }
    }
  }
  .healthReport-c {
    background: #fff;
    padding: 52px 34px 44px;
    position: relative;
    z-index: 1;
    .advise {
      background: #fbfbfb;
      border-radius: 20px;
      padding: 28px;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
    }
    .oTable {
      border-top: 2px solid #abced2;
      border-left: 2px solid #abced2;
      margin: 26px 0 60px;
      .item {
        display: flex;
        align-items: center;
        height: 60px;
        box-sizing: border-box;
        .item-l,
        .item-c,
        .item-r {
          flex: 1;
          height: 100%;
          box-sizing: border-box;
          text-align: center;
          padding: 12px 0;
          border-right: 2px solid #abced2;
          border-bottom: 2px solid #abced2;
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
        }
      }
    }
    .targetItem {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 134px 0 40px;
      justify-content: space-between;
      .item {
        position: relative;
        .num {
          position: absolute;
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
          top: -44px;
          left: -12px;
          z-index: 6;
        }
        .item-b {
          margin-top: 40px;
          font-size: 22px;
          font-weight: 400;
          color: rgba(118, 192, 248, 1);
          line-height: 34px;
          text-align: center;
        }
        .item-t {
          width: 130px;
          height: 10px;
          border-radius: 5px;
          margin: 0 auto;
          background: linear-gradient(90deg, #e3fafc 0%, #c0ebf0 100%);
          position: relative;
          .circle {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            position: absolute;
            top: -14px;
            z-index: 99;
            left: 0.1rem;
            background: #089bab;
            box-shadow: 0px 2px 14px 0px rgba(207, 216, 209, 0.5);
            border: 4px solid #ffffff;
            .numItem {
              width: 100px;
              height: 60px;
              background: #fbfbfb;
              border-radius: 12px;
              text-align: center;
              font-size: 30px;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.85);
              line-height: 60px;
              position: absolute;
              left: -50px;
              top: -100px;
            }
          }
        }
      }
      .item2 {
        .item-t {
          background: linear-gradient(270deg, #99dae1 0%, #c0ebf0 50%);
        }
        .item-b {
          color: rgba(36, 182, 83, 1);
        }
      }
      .item3 {
        .item-t {
          background: linear-gradient(270deg, #99dae1 0%, #c0ebf0 100%);
        }
        .item-b {
          color: rgba(36, 182, 83, 1);
        }
      }
      .item4 {
        .item-t {
          background: linear-gradient(270deg, #99dae1 0%, #79cdd6 100%);
        }
        .item-b {
          color: rgba(247, 181, 0, 1);
        }
      }
      .item5 {
        .item-t {
          background: linear-gradient(270deg, #79cdd6 0%, #57bac5 100%);
        }
        .item-b {
          color: rgba(250, 100, 0, 1);
        }
      }

      .item6 {
        .item-t {
          background: linear-gradient(270deg, #57bac5 0%, #089bab 100%);
        }
        .item-b {
          color: rgba(224, 32, 32, 1);
        }
      }
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      color: #089bab;
      line-height: 44px;
      padding-left: 22px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 36px;
        background: #089bab;
        border-radius: 8px;
        left: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .intro {
      font-size: 28px;
      margin: 20px 0;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
    }
    .thirdItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 56px;
      .item {
        width: 214px;
        height: 114px;
        background: rgba(8, 155, 171, 0.05);
        border-radius: 20px;
        border: 2px solid #089bab;
        text-align: center;
        padding-top: 16px;
        box-sizing: border-box;
        .item-t {
          font-size: 24px;
          font-weight: 400;
          color: #089bab;
          line-height: 34px;
        }
        .item-b {
          font-size: 36px;
          font-weight: 600;
          color: #089bab;
          line-height: 50px;
          position: relative;
          display: inline-block;
        }
      }
      .active {
        .item-b {
          &::after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            background: #f7b500;
            border-radius: 6px;
            right: -16px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }
  .peopleComponent {
    padding: 0;
    margin-top: -18px;
    .peopleComponent-t {
      height: 572px;
      background: url("./img/topback.png") no-repeat;
      background-size: 100% 100%;
      padding: 0 28px;
      .peopleComponent-tt {
        display: flex;
        justify-content: space-between;
        padding: 46px 62px 0 44px;
        .peopleComponent-ttl {
          width: 82px;
          height: 226px;
          margin-right: 70px;
          .fat1 {
            width: 72px;
            height: 226px;
          }
          .fat2 {
            width: 74px;
            height: 224px;
          }
          .fat3 {
            width: 82px;
            height: 224px;
          }
          .fat4 {
            width: 90px;
            height: 226px;
          }
          .fat5 {
            width: 106px;
            height: 226px;
          }
        }
        .peopleComponent-ttr {
          display: flex;
          flex: 1;
          justify-content: space-between;
          padding-top: 12px;
          .peopleComponent-ttrlt {
            font-size: 24px;
            font-weight: 400;
            color: #089bab;
            line-height: 34px;
          }
          .peopleComponent-ttrlb {
            font-size: 52px;
            font-weight: 600;
            color: #089bab;
            line-height: 74px;
            margin-top: 34px;
            span {
              font-size: 28px;
            }
          }
          .peopleComponent-ttrr {
            text-align: center;
          }
          .peopleComponent-ttrrt {
            font-size: 24px;
            font-weight: 400;
            color: #089bab;
            line-height: 34px;
          }
          .peopleComponent-ttrrb {
            font-size: 52px;
            font-weight: 600;
            color: #089bab;
            line-height: 74px;
            margin-top: 34px;
          }
        }
      }
      .peopleComponent-tb {
        height: 250px;
        background: url("./img/zhiBack.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 20px;
        padding: 32px 54px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .item-t {
          width: 142px;
          height: 142px;
          padding-top: 34px;
          box-sizing: border-box;
          background: #ffffff;
          border: 3px solid #fff3d2;
          font-size: 32px;
          font-weight: 500;
          color: #ffcd45;
          line-height: 44px;
          text-align: center;
          border-radius: 50%;
          .returnIsComTxt {
            text-align: center;
            font-size: 24px;
            font-weight: 400;
            color: #d0d0d0;
            line-height: 34px;
          }
        }
        .item-b {
          display: flex;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          .img {
            width: 26px;
            height: 26px;
            margin-right: 6px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            font-size: 26px;
            font-weight: 500;
            color: #ffcd45;
            line-height: 36px;
          }
        }

        .MuscleMass {
          .item-t {
            border: 3px solid #d0f3ff;
            color: rgba(0, 190, 255, 1);
          }
          .item-b {
            .txt {
              color: rgba(0, 190, 255, 1);
            }
          }
        }
        .BodyWaterPercentage {
          .item-t {
            border: 3px solid #dbf5f5;
            color: rgba(27, 197, 156, 1);
          }
          .item-b {
            .txt {
              color: rgba(27, 197, 156, 1);
            }
          }
        }
      }
    }
    .peopleComponent-c {
      padding: 20px 34px;
      background: #fff;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
    }

    .peopleComponent-b {
      padding: 0 34px 50px;
      .title {
        margin: 86px 0 44px;
      }
      .peopleComponent-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item {
          .item-t {
            width: 186px;
            height: 186px;
          }
          .item-b {
            margin: 18px 0;
            font-size: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 34px;
            .item-bt,
            .item-bb {
              position: relative;
              padding-left: 22px;
              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 10px;
                height: 10px;
                background: #dfeeff;
                border-radius: 50%;
              }
            }
          }
        }
        .Weight {
          #Weight {
            width: 100%;
            height: 100%;
          }
          .item-b {
            .item-bb {
              &::before {
                background: rgba(134, 190, 253, 1);
              }
            }
          }
        }
        .MuscleMass {
          #MuscleMass {
            width: 100%;
            height: 100%;
          }
          .item-b {
            .item-bt {
              &::before {
                background: rgba(252, 215, 115, 0.3);
              }
            }
            .item-bb {
              &::before {
                background: rgba(252, 215, 115, 1);
              }
            }
          }
        }
        .NoFat {
          #NoFat {
            width: 100%;
            height: 100%;
          }
          .item-b {
            .item-bt {
              &::before {
                background: rgba(150, 216, 201, 0.3);
              }
            }
            .item-bb {
              &::before {
                background: rgba(150, 216, 201, 1);
              }
            }
          }
        }
      }
      .peopleComponentList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
        .item {
          flex-shrink: 1;
          width: 212px;
          height: 186px;
          background: #f3fafb;
          border-radius: 16px;
          border: 2px solid #abced2;
          padding: 24px 0 0 18px;
          box-sizing: border-box;
          margin-bottom: 20px;
          .item-t {
            font-size: 24px;
            font-weight: 400;
            color: #898989;
            line-height: 34px;
          }
          .item-c {
            margin: 10px 0 14px;
          }
          .item-b {
            font-size: 40px;
            font-weight: 600;
            color: #089bab;
            line-height: 56px;
          }
        }
      }
    }
  }
  .dongmai {
    .intro {
      margin: 20px 0 74px 0;
    }
    .targetItem {
      .item {
        .item-t {
          width: 164px;
          height: 10px;
        }
      }
    }
    .High {
      .targetItem {
        .item {
          .item-t {
            width: 100px;
            height: 10px;
          }
        }
      }
    }
    .Heart {
      .targetItem {
        .item {
          .item-t {
            width: 220px;
            height: 10px;
          }
        }
      }
    }

    .careInfo {
      font-size: 24px;
      margin: 0 0 70px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.35);
      line-height: 34px;
    }
  }
  .BMI {
    .targetItem {
      .item {
        .item-t {
          width: 164px;
          height: 10px;
        }
      }
    }
  }
  .boneMD {
    .time {
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.35);
      line-height: 34px;
      margin: 24px 0 30px;
    }
    .sexAge {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: 14px 28px;
      background: rgba(8, 155, 171, 0.05);
      border-radius: 6px;
      border: 2px solid #089bab;
      margin: 30px 0 16px;
      font-size: 28px;
      font-weight: 400;
      color: #089bab;
      line-height: 40px;
      &::after {
        content: "";
        position: absolute;
        width: 2px;
        background: #089bab;
        height: 48px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .targetItem {
      .item {
        .item-t {
          width: 220px;
          height: 10px;
        }
      }
    }
  }
  .healthReport-b {
    height: 212px;
    background: url("./img/bottomBack.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }
}
</style>
